import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="এখানে লিখুন" description="আপনিও লিখুন বালের ব্লগে" mdxType="SEO" />
    <h1 {...{
      "id": "লিখবে-সবাই"
    }}>{`লিখবে সবাই`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "688px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/webp;base64,UklGRpQAAABXRUJQVlA4IIgAAABQBACdASoUAAoAPtFUo0uoJKMhsAgBABoJQBOmUABfkmGiTRUvMAGrz9oAAP7rvq5y6wsRJ130BfFZj+3/ZR9N8lMi94fGeovVii+aVvH1FmsEOdSAPkfP6sGCOH9rZk7btQcnsG8S4S7s66a0nYS/erz/EF66VOkwWpMeUdX2zcwMFliTAAAA')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/d4a89633a6b7096f0f64b55c945cb507/d3be9/writing_unsplash.webp 480w", "/static/d4a89633a6b7096f0f64b55c945cb507/01c7f/writing_unsplash.webp 688w"],
            "sizes": "(max-width: 688px) 100vw, 688px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/d4a89633a6b7096f0f64b55c945cb507/d3be9/writing_unsplash.webp 480w", "/static/d4a89633a6b7096f0f64b55c945cb507/01c7f/writing_unsplash.webp 688w"],
            "sizes": "(max-width: 688px) 100vw, 688px",
            "type": "image/webp"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/d4a89633a6b7096f0f64b55c945cb507/01c7f/writing_unsplash.webp",
            "alt": "বালের ব্লগে লিখুন",
            "title": "source: unsplash/glenncarstenspeters",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>
    <p><strong parentName="p">{`বালের ব্লগ`}</strong>{` সবার জন্য উন্মুক্ত। চাইলে আপনিও লিখতে পারেন এখানে। আপনার যেকোনো লিখা পাঠিয়ে দিন আমাদের ই-মেইল বা ফেসবুক পেজে। `}</p>
    <ul>
      <li parentName="ul">{`ই-মেইল: `}<strong parentName="li"><a parentName="strong" {...{
            "href": "mailto:content@baal.xyz",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`content@baal.xyz`}</a></strong></li>
      <li parentName="ul">{`ফেসবুক পেজ: `}<strong parentName="li"><a parentName="strong" {...{
            "href": "http://fb.com/baal.xyz",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`বালের ব্লগ`}</a></strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      